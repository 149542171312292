import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ContentService } from '../content/content.service';
import { AppStateService } from '../app-state/app-state.service';
import { Observable, map, of, switchMap, tap } from 'rxjs';

type DialogParams = {
  panelClass: string;
  backdropClass: string;
  autoFocus?: boolean;
  closeOnNavigation?: boolean;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    public dialog: MatDialog,
    private contentService: ContentService,
    private appState: AppStateService,
  ) {
  }


  /**
   * @description Generic dialog opener with support for Contenful content
   * @param ref Reference of Dialog Component
   * @param content String of contentfull content collection items.
   * @param dialogParams Map of properties like `panelClass` and `backdropClass` and possible data you want to pass on to the dialog component
   * @returns Returns short lives observable value of MatDialogRef
   */
  showDialog<T>(ref: any, content?: string[], dialogConfig?: MatDialogConfig): Observable<MatDialogRef<T, any>> {
    if (content) {
      return this.contentService.getContent(content)
        .pipe(
          switchMap(() => {
            const keys = extractCollectionKeys();
            for (const key of keys) {
              const col = this.appState.contentItemCollection[key];
              if (col) {
                // if we have more than 1 groups of content, we need to include groupname (key) to prevent overwrites
                if (keys.length > 1) {
                  dialogConfig.data = {
                    ...dialogConfig.data,
                    [key]: col,
                  }
                } else {
                  dialogConfig.data = {
                    ...dialogConfig.data,
                    ...col,
                  }
                }
              }
            }
            dialogConfig.width = dialogConfig.width ? dialogConfig.width : 'auto';
            dialogConfig.maxWidth = dialogConfig.maxWidth ? dialogConfig.maxWidth : 'auto';
            dialogConfig.autoFocus = false;
            dialogConfig.closeOnNavigation = dialogConfig.closeOnNavigation ? dialogConfig.closeOnNavigation : true;
            return of(this.dialog.open<T>(ref, dialogConfig))
          }))
    } else {
      return of(this.dialog.open<T>(ref, {
        ...dialogConfig,
        autoFocus: false,
        width: dialogConfig.width ? dialogConfig.width : 'auto',
        maxWidth: dialogConfig.maxWidth ? dialogConfig.maxWidth : 'auto',
        closeOnNavigation: dialogConfig.closeOnNavigation ? dialogConfig.closeOnNavigation : true,
      }));
    }

    function extractCollectionKeys() {
      // use Set to remove duplicate keys
      const collections = new Set(content.map(val => val.split(':').shift()));
      return Array.from(collections);
    }
  }
}
